import React from "react";
import { connect } from "react-redux";
import Calendar from "../../core/calendar/calendar";

const TeamCalendar = (params) => {
    return (
        <div>
            <Calendar user={params.identity.user} type="use" eventType="articles" />
        </div>
    );
};

function mapStateToProps(state) {
    const { identity } = state;
    return { identity };
}

export default connect(mapStateToProps)(TeamCalendar);
