import { CheckBox } from "devextreme-react";
import React, { useCallback, useEffect, useState } from "react";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
    deleteEventFromTeamCalendar,
    fetchCalendarEventsTimespan,
    fetchTeamCalendarEventsTimespan,
    updateEventFromTeamCalendar,
} from "../../../actions/teamCalendarActions";
import { getUserLanguage } from "../../../actions/userActions";
import { getGrammageUnit } from "../../../helpers/units";
import store from "../../../store";
import FillModeCalendar from "./fillModeCalendar";
import UseModeCalendar from "./useModeCalendar";
import ViewModeCalendar from "./viewModeCalendar";

const calendarModes = [
    { id: 1, name: "view", displayExpr: "Betrachten" },
    { id: 2, name: "fill", displayExpr: "Füllen" },
    { id: 3, name: "use", displayExpr: "Benutzen" },
];
const eventTypes = [
    { id: 1, name: "tasks", displayExpr: "Aufgaben" },
    { id: 2, name: "articles", displayExpr: "Kochprozesse" },
    { id: 3, name: "employees", displayExpr: "Mitarbeiter" },
];

function Calendar(params) {
    const [events, setEvents] = useState([]);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [articles, setArticles] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [showTasks, setShowTasks] = useState(false);
    const [showArticles, setShowArticles] = useState(false);
    const [showEmployees, setShowEmployees] = useState(false);
    const calendarMode = params.type ? calendarModes.find((cm) => cm.name === params.type) : calendarModes[0];
    const [currentRange, setCurrentRange] = useState({ start: null, end: null });
    const [language, setLanguage] = useState("");
    const [eventType, setEventType] = useState(params.eventType ? eventTypes.find((et) => et.name === params.eventType) : undefined);
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        const currentWeek = getCurrentWeek();
        const startDate = currentRange.start || currentWeek.startOfWeek;
        const endDate = currentRange.end || currentWeek.endOfWeek;
        let isSubscribed = true;

        if (params.teamCalendarId) {
            fetchEventsForTeamCalendar(startDate, endDate, isSubscribed);
        } else {
            fetchEvents(startDate, endDate, isSubscribed);
        }

        if (!showTasks && !showArticles && !showEmployees) {
            if (calendarMode === calendarModes[2] && eventType) {
                setShowTasks(eventType === eventTypes[0]);
                setShowArticles(eventType === eventTypes[1]);
            } else {
                setShowTasks(calendarMode === calendarModes[0]);
                setShowArticles(true);
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [params.user, currentRange, params.teamCalendarId, params.updatedEvents, refetch]);

    useEffect(() => {
        if (params.user && params.user.selectedLanguage) {
            setLanguage(params.user.selectedLanguage);
        } else {
            store.dispatch(getUserLanguage()).then((response) => setLanguage(response));
        }
    }, [params.user]);

    useEffect(() => {
        setTasks(events.filter((event) => event.type === 1));
        setArticles(events.filter((event) => event.type === 2));
        setEmployees(events.filter((event) => event.type === 3));
    }, [events]);

    useEffect(() => {
        articles.forEach((a) => {
            updateEmployeesOnArticle(a);
        });
    }, [employees]);

    useEffect(() => {
        setDisplayEvents(getEventsToDisplay());
    }, [events, showTasks, showArticles, showEmployees]);

    function fetchEvents(startDate, endDate, isSubscribed) {
        store
            .dispatch(fetchCalendarEventsTimespan(params.user.Id, startDate.toISOString(), endDate.toISOString()))
            .then((response) => {
                if (isSubscribed) {
                    const formattedEvents = response.map((event) => ({
                        ...event,
                        start: new Date(event.start),
                        end: new Date(event.end),
                    }));
                    setEvents(formattedEvents);
                }
            });
    }

    function fetchEventsForTeamCalendar(startDate, endDate, isSubscribed) {
        store
            .dispatch(
                fetchTeamCalendarEventsTimespan(params.teamCalendarId, startDate.toISOString(), endDate.toISOString())
            )
            .then((response) => {
                if (isSubscribed) {
                    const formattedEvents = response.map((event) => ({
                        ...event,
                        start: new Date(event.start),
                        end: new Date(event.end),
                    }));
                    setEvents(formattedEvents);
                }
            });
    }

    function getEventsToDisplay() {
        let displayEvents = [];
        if (showTasks) {
            displayEvents = displayEvents.concat(events.filter((event) => event.type === 1));
        }
        if (showArticles) {
            displayEvents = displayEvents.concat(events.filter((event) => event.type === 2));
        }
        if (showEmployees) {
            displayEvents = displayEvents.concat(events.filter((event) => event.type === 3));
        }
        return displayEvents;
    }

    function getCurrentWeek() {
        const currentDate = new Date();
        const weekStart = currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1);
        const weekEnd = weekStart + 6;

        const startOfWeek = new Date(currentDate.setDate(weekStart));
        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(currentDate.setDate(weekEnd));
        endOfWeek.setHours(23, 59, 59, 999);

        return { startOfWeek, endOfWeek };
    }

    function updateEmployeesOnArticle(article) {
        const overlappingEmployees = employees.filter((e) => e.start <= article.start && e.end >= article.end);
        article.currentEmployees = overlappingEmployees.length;
    }

    const handleRangeChange = (range) => {
        if (Array.isArray(range)) {
            setCurrentRange({
                start: new Date(range[0].setHours(0, 0, 0, 0)),
                end: new Date(range[range.length - 1].setHours(23, 59, 59, 999)),
            });
        } else {
            setCurrentRange({
                start: new Date(range.start.setHours(0, 0, 0, 0)),
                end: new Date(range.end.setHours(23, 59, 59, 999)),
            });
        }
    };

    const handleEventDrop = useCallback(
        ({ event, start, end }) => {
            if (!event.cookingProcessStart) {
                const updatedEvent = { ...event, start, end };
                setEvents((prevEvents) => {
                    const newEvents = prevEvents.map((existingEvent) =>
                        existingEvent.id === event.id ? updatedEvent : existingEvent
                    );
                    return newEvents;
                });
                store.dispatch(updateEventFromTeamCalendar(updatedEvent));
            }
        },
        [events]
    );

    const handleEventResize = useCallback(
        ({ event, start, end }) => {
            if (!event.cookingProcessStart) {
                const updatedEvent = { ...event, start, end };
                setEvents((prevEvents) => {
                    const newEvents = prevEvents.map((existingEvent) =>
                        existingEvent.id === event.id ? updatedEvent : existingEvent
                    );
                    return newEvents;
                });
                store.dispatch(updateEventFromTeamCalendar(updatedEvent));
            }
        },
        [events]
    );

    const handleRefetch = () => {
        setRefetch(!refetch);
    };

    const showEventType = (show, type) => {
        if (!show && calendarMode === calendarModes[1]) {
            return;
        }
        if (show && calendarMode === calendarModes[1]) {
            setShowArticles(false);
            setShowTasks(false);
            setShowEmployees(false);
            setEventType(eventTypes.find(et => et.name === type));
        }
        if (type === "articles") {
            setShowArticles(show);
        } else if (type === "tasks") {
            setShowTasks(show);
        } else if (type === "employees") {
            setShowEmployees(show);
        }
    }

    const renderEventContent = useCallback((slotInfo) => {
        let amount = getEventAmount(slotInfo.event);
        return (
            <div>
                <p>{amount + " " + slotInfo.title}</p>
                {slotInfo.event.type === 2 && (
                    <p>
                        ({slotInfo.event.currentEmployees}/{Math.max(1, slotInfo.event.requiredEmployees.length)})
                    </p>
                )}
            </div>
        );
    }, []);

    const getEventAmount = (event) =>{
        let amount = "";
        if (event.articleWeight > 0) {
            if (getGrammageUnit(event.articleWeight) === "kg") {
                amount = event.articleWeight / 1000 + "kg";
            } else {
                amount = event.articleWeight + "g";
            }
        } else if (event.articlePortions > 0) {
            amount = event.articlePortions + "x";
        }
        return amount;
    }

    const getTooltip = (event) => {
        let amount = getEventAmount(event);
        return amount + " " + event.title;
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.type === 1 ? "red" : "blue";
        if (event.type === 2) {
            updateEmployeesOnArticle(event);
            backgroundColor =
                event.currentEmployees === 0
                    ? "red"
                    : event.currentEmployees < event.requiredEmployees.length
                    ? "orange"
                    : "green";
        }
        if (event.cookingProcessStart) {
            backgroundColor = "grey";
        }
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: "0px",
            opacity: 0.8,
            color: "black",
            border: "0px",
            display: "block",
        };
        if (event.hide) {
            return {
                style: { visibility: "hidden" },
            };
        }
        return {
            style: style,
        };
    };

    return (
        <div>
            {calendarMode !== calendarModes[2] && (
                <div style={{ margin: "20px" }}>
                    <CheckBox
                        value={showTasks}
                        style={{ marginRight: "20px" }}
                        text="Aufgaben"
                        onOptionChanged={(e) => showEventType(e.value, "tasks")}
                    />
                    <CheckBox
                        value={showArticles}
                        style={{ marginRight: "20px" }}
                        text="Kochprozesse"
                        onOptionChanged={(e) => showEventType(e.value, "articles")}
                    />
                    {calendarMode !== calendarModes[0] && (
                        <CheckBox
                        value={showEmployees}
                            text="Mitarbeiter"
                            onOptionChanged={(e) => showEventType(e.value, "employees")}
                        />
                    )}
                </div>
            )}
            {calendarMode === calendarModes[0] && (
                <ViewModeCalendar
                    events={displayEvents}
                    handleRangeChange={handleRangeChange}
                    eventStyleGetter={eventStyleGetter}
                    renderEventContent={renderEventContent}
                    culture={language}
                    tooltipAccessor={getTooltip}
                />
            )}
            {calendarMode === calendarModes[1] && (
                <FillModeCalendar
                    events={displayEvents}
                    handleEventDrop={handleEventDrop}
                    handleEventResize={handleEventResize}
                    handleRangeChange={handleRangeChange}
                    eventStyleGetter={eventStyleGetter}
                    culture={language}
                    handleAmountChange={params.handleAmountChange}
                    handleEventDelete={params.handleEventDelete}
                    onDropFromOutside={params.onDropFromOutside}
                    dragFromOutsideItem={params.dragFromOutsideItem}
                    recipes={params.recipes}
                    employees={params.employees}
                    teamCalendarId={params.teamCalendarId}
                    forceRefetch={handleRefetch}
                    eventType={eventType}
                    hasActivePopup={params.hasActivePopup}
                    tooltipAccessor={getTooltip}
                />
            )}
            {calendarMode === calendarModes[2] && (
                <UseModeCalendar
                    events={displayEvents}
                    handleRangeChange={handleRangeChange}
                    eventStyleGetter={eventStyleGetter}
                    renderEventContent={renderEventContent}
                    culture={language}
                    eventType={eventType}
                    tooltipAccessor={getTooltip}
                />
            )}
        </div>
    );
}

export default Calendar;
