import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchTeamCalendars() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getTeamCalendars/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function fetchTeamCalendar(teamCalendarId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getTeamCalendar/" + teamCalendarId,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function updateTeamCalendar(teamCalendar) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(teamCalendar)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/updateTeamCalendar/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function addTeamCalendar(teamCalendarName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(teamCalendarName)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/createTeamCalendar/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function deleteTeamCalendar(teamCalendarId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
        body: JSON.stringify(teamCalendarId)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/deleteTeamCalendar/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function addEventToTeamCalendar(event) {
    event.start = formatDate(event.start);
    event.end = formatDate(event.end);
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(event)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/addEventToTeamCalendar/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function getCalendarEvent(eventId){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getCalendarEvent/" + eventId,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function getCalendarEvents(userId, start, end){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getCalendarEvents/" + userId + "/" + start + "/" + end,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function updateEventFromTeamCalendar(event) {
    const eventDto = {
        eventId: event.id || event.eventId,
        name: event.title || event.name,
        startDateTime: formatDate(event.start || event.startDateTime),
        endDateTime: formatDate(event.end || event.endDateTime),
        cookingProcessStartDateTime: formatDate(event.cookingProcessStart),
        cookingProcessEndDateTime: formatDate(event.cookingProcessEnd),
        cookingProcessId: event.cookingProcessId,
        articleWeight: event.articleWeight,
        articlePortions: event.articlePortions,
    };
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(eventDto)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/updateEventFromTeamCalendar/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function fetchCalendarEvent(eventId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getCalendarEvent/" + eventId,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function fetchCalendarEventsTimespan(userId, start, end) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getCalendarEvents/" + userId + "/" + start + "/" + end,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function fetchTeamCalendarEventsTimespan(teamCalendarId, start, end) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getTeamCalendarEvents/" + teamCalendarId + "/" + start + "/" + end,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function deleteEventFromTeamCalendar(eventId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/deleteEventFromTeamCalendar/" + eventId,
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function fetchEmployees() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/teamCalendar/getEmployees/",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

const formatDate = (date) => {
    if (!date) {
        return null;
    }
    if (!(date instanceof Date)) {
        return date;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };