import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {Button, LoadIndicator } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import {fetchTeamCalendar, updateTeamCalendar } from '../../../../../actions/teamCalendarActions';
import store from '../../../../../store';
import './teams.scss';

const TeamCalendar = () => {
  const { teamCalendarId } = useParams();
  const [teamCalendar, setTeamCalendar] = useState(null);

  useEffect(() => {
    const loadTeamCalendar = async () => {
      store.dispatch(fetchTeamCalendar(teamCalendarId)).then((response) => setTeamCalendar(response));
    };

    loadTeamCalendar();
  }, [teamCalendarId]);

  const handleSaveChanges = async () => {
    if (teamCalendar) {
        teamCalendar.teamCalendarId = parseInt(teamCalendarId);
        store.dispatch(updateTeamCalendar(teamCalendar)).then((response) => {
            if (response.status === 200) {
                alert('Änderungen erfolgreich gespeichert');
            }
        });
    }
  };

  const handleChange = (value, field) => {
    setTeamCalendar({
      ...teamCalendar,
      [field]: value
    });
  };

  return (
    <div style={{ margin: 10 }}>
      {teamCalendar ? (
        <>
            <div className="form-group">
                <label htmlFor="nameInput" className="label">Name:</label>
                <TextBox
                    className='team-textBox'
                    value={teamCalendar.name}
                    onValueChanged={(e) => handleChange(e.value, 'name')}
                    placeholder="Team-Kalender Name"
                />
            </div>
            <div className="form-group">
                <label htmlFor="descriptionInput" className="label">Beschreibung:</label>
                <TextBox
                    className='team-textBox'
                    value={teamCalendar.description}
                    onValueChanged={(e) => handleChange(e.value, 'description')}
                    placeholder="Beschreibung"
                />
            </div>
            <div className="buttonContainer">
                <Button
                    text="Änderungen speichern"
                    type="default"
                    onClick={handleSaveChanges}
                />
            </div>
        </>
      ) : (
        <LoadIndicator width={40} height={40} />
      )}
    </div>
  );
};

export default TeamCalendar;
