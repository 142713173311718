import React, { useCallback, useEffect, useState } from "react";
import "./questManagement.scss";
import { Button } from "devextreme-react";
import store from "../../../store";
import { getAllQuestFolders } from "../../../actions/questManagementAction";
import Popups from "./popups/popups";

export const QuestManagementContext = React.createContext();

export default function QuestManagement() {
    const [folders, setFolders] = useState([]);
    const [addFolderPopupVisible, setAddFolderPopupVisible] = useState(false);

    const toggleAddFolderPopup = useCallback(() => {
        setAddFolderPopupVisible(!addFolderPopupVisible);
    }, [addFolderPopupVisible]);

    const getFolders = useCallback(() => {
        store.dispatch(getAllQuestFolders()).then((data) => {
            setFolders(data);
        });
    }, []);

    useEffect(() => {
        getFolders();
    }, []);

    return (
        <QuestManagementContext.Provider value={{ addFolderPopupVisible, toggleAddFolderPopup }}>
            <div id="QuestManagement">
                {folders.length === 0 && <Button text="Add Folder" onClick={toggleAddFolderPopup} />}
                <Popups />
            </div>
        </QuestManagementContext.Provider>
    );
}
