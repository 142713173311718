import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllQuestFolders() {
    return function () {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/questManagement/getAllQuestFolders", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function insertQuestFolderFromNameAndDescription(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function () {
        return fetch(
            config.backendHost + "/questManagement/insertQuestFolderFromNameAndDescription",
            requestOptions
        ).then((response) => handleResponse(response));
    };
}
