import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Button, Popup, Switch } from "devextreme-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { addEventToTeamCalendar } from "../../../actions/teamCalendarActions";
import store from "../../../store";
import "./calendar.scss";

class CalendarSlotPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEmployee: false,
            dropdownValue: "",
            currentAmount: "0",
            switchedOn: true,
            error: "",
        };

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleSwitchOptionChanged = this.handleSwitchOptionChanged.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCreateEvent = this.handleCreateEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.recipes !== this.props.recipes || prevProps.employees !== this.props.employees) {
            if (this.props.eventType && this.props.eventType.name === "employees") {
                this.setState({ dropdownValue: "e-" + this.props.employees[0].employeesId });
            } else if (this.props.recipes && this.props.recipes.length > 0) {
                this.setState({ dropdownValue: "r-" + this.props.recipes[0].articlesId });
            }
        }
        if (prevProps.eventType !== this.props.eventType) {
            this.setState({ isEmployee: this.props.eventType.name === "employees" });
        }
        if (prevProps.event !== this.props.event) {
            this.setState({ error: "" });
        }
    }

    handleDropdownChange(event) {
        this.setState({ dropdownValue: event.target.value });
    }

    handleSwitchOptionChanged() {
        this.setState({
            switchedOn: !this.state.switchedOn,
        });
    }

    handleAmountChange(event) {
        const value = event.target.value;
        if (value === "" || (Number(value) > 0 && !isNaN(value))) {
            this.setState({ currentAmount: value, error: "" });
        } else {
            this.setState({ error: "Die Menge muss größer als 0 sein." });
        }
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            if (Number(e.target.value) === 0) {
                this.setState({ error: "Die Menge muss größer als 0 sein." });
                return;
            }
            this.setState({ currentAmount: e.target.value });
            if (this.props.event) {
                this.props.handleCreateEvent(
                    this.state.currentAmount,
                    this.props.event,
                    this.state.switchedOn ? "Portionen" : "g"
                );
            } else {
                this.handleCreateEvent();
            }
        }
    }

    handleCreateEvent() {
        if (Number(this.state.currentAmount) === 0) {
            this.setState({ error: "Die Menge muss größer als 0 sein." });
            return;
        }
        if (this.props.event) {
            this.props.handleCreateEvent(
                this.state.currentAmount,
                this.props.event,
                this.state.switchedOn ? "Portionen" : "g"
            );
            this.setState({currentAmount: 0});
            return;
        }
        let event;
        if (this.state.dropdownValue.startsWith("r")) {
            const recipe = this.props.recipes.find((r) => "r-" + r.articlesId === this.state.dropdownValue);
            event = {
                teamCalendarId: this.props.teamCalendarId,
                name: recipe.name,
                articleId: recipe.articlesId,
                start: this.props.slot.start,
                end: new Date(this.props.slot.start.getTime() + Math.max(30, recipe.recipeTime) * 60000),
            };
            if (this.state.switchedOn) {
                event.articlePortions = this.state.currentAmount;
            } else {
                event.articleWeight = this.state.currentAmount;
            }
        } else if (this.state.dropdownValue.startsWith("e")) {
            const employee = this.props.employees.find((e) => "e-" + e.employeesId === this.state.dropdownValue);
            event = {
                teamCalendarId: this.props.teamCalendarId,
                name: employee.name,
                employeeId: employee.employeesId,
                start: this.props.slot.start,
                end: new Date(this.props.slot.start.getTime() + 28800000),
            };
        }
        if (event) {
            store.dispatch(addEventToTeamCalendar(event)).then((response) => {
                if (response) {
                    this.props.forceRefetch();
                    this.props.togglePopup();
                    this.setState({currentAmount: 0});
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        if (!this.props.slot && !this.props.event) {
            return null;
        }
        const amount = 0;

        return (
            <Popup
                visible={this.props.visible}
                onHidden={this.props.togglePopup}
                closeOnOutsideClick
                height={this.state.isEmployee ? 200 : 300}
                title={t("Event erstellen")}
                width={500}
            >
                <div className="centeredDiv verticalFlex">
                    {this.props.event && <h2>{this.props.event.name}</h2>}
                    {!this.props.event && (
                        <FormControl fullWidth style={{ marginBottom: 10 }}>
                            <InputLabel id="demo-simple-select-label">
                                {this.state.isEmployee ? "Mitarbeiter" : "Rezept"}
                            </InputLabel>
                            <Select
                                id="demo-simple-select"
                                value={this.state.dropdownValue}
                                onChange={this.handleDropdownChange}
                                MenuProps={{
                                    style: { zIndex: 3000 },
                                }}
                            >
                                {this.state.isEmployee &&
                                    this.props.employees.map((employee) => (
                                        <MenuItem key={"e-" + employee.employeesId} value={"e-" + employee.employeesId}>
                                            {employee.name}
                                        </MenuItem>
                                    ))}
                                {!this.state.isEmployee &&
                                    this.props.recipes.map((recipe) => (
                                        <MenuItem key={"r-" + recipe.articlesId} value={"r-" + recipe.articlesId}>
                                            {recipe.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    {!this.state.isEmployee && (
                        <div className="centeredDiv verticalFlex" style={{ marginBottom: 10 }}>
                            <Switch
                                defaultValue={this.state.switchedOn}
                                switchedOnText={t("Portionen")}
                                switchedOffText={t("Gewicht")}
                                width={100}
                                height={40}
                                onOptionChanged={this.handleSwitchOptionChanged}
                                style={{ float: "right" }}
                            />
                            <div className="centeredDiv horizontalFlex">
                                <h2>{t("Menge")}</h2>
                                <p className="eventAmount">
                                    <input
                                        type="number"
                                        min="0"
                                        className="eventAmountInput"
                                        defaultValue={amount}
                                        contentEditable={true}
                                        onChange={this.handleAmountChange}
                                        onKeyDown={this.handleKeyDown}
                                        style={{ width: "70px" }}
                                    />
                                    {!this.state.switchedOn && t("g")}
                                    {this.state.switchedOn && t("Portionen")}
                                </p>
                            </div>
                            {this.state.error && <div style={{ color: "red" }}>{t(this.state.error)}</div>}
                        </div>
                    )}
                    <div className="horizontalFlex">
                        <Button type="default" className="popupButton" onClick={this.handleCreateEvent}>
                            {t("Erstellen")}
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(CalendarSlotPopup);
