import { Button, Popup, Switch } from "devextreme-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./calendar.scss";

class CalendarEventPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentAmount: this.getInitialAmount(props),
            confirmPopupVisible: false,
            switchedOn: true,
            unit: "Portionen",
        };

        this.handleSwitchOptionChanged = this.handleSwitchOptionChanged.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.toggleConfirmPopup = this.toggleConfirmPopup.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.event !== this.props.event &&
            (this.props.isEmployee === undefined || this.props.isEmployee === false)
        ) {
            this.setState({ currentAmount: this.getInitialAmount(this.props), error: "" });
            this.setState({
                switchedOn: this.props.unit === "Portionen",
                unit: this.props.unit,
            });
        }
    }

    handleSwitchOptionChanged = (e) => {
        this.setState((prevState) => {
            let newUnit;
            if (e.value) {
                newUnit = "Portionen";
            } else if (this.getAmountUnit() !== "Portionen") {
                newUnit = this.getAmountUnit();
            } else {
                newUnit = "g";
            }
            return {
                unit: newUnit,
                switchedOn: !this.state.switchedOn,
            };
        });
    };

    getInitialAmount(props) {
        if (props.event) {
            const { event } = props;
            if (event.articleWeight > 0) {
                return event.articleWeight;
            } else if (event.articlePortions > 0) {
                return event.articlePortions;
            }
        }
        return 0;
    }

    getAmountUnit() {
        let unit = "";
        if (this.props.event.articleWeight > 0) {
            unit = "g";
        } else {
            unit = "Portionen";
        }
        return unit;
    }

    handleAmountChange(e) {
        const value = e.target.value;
        if (value === "" || (Number(value) > 0 && !isNaN(value))) {
            this.setState({ currentAmount: value, error: "" });
        } else {
            this.setState({ error: "Die Menge muss größer als 0 sein." });
        }
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.saveUpdatedAmount(this.props, e);
        }
    }

    saveUpdatedAmount(props, e) {
        if (Number(this.state.currentAmount) === 0) {
            this.setState({ error: "Die Menge muss größer als 0 sein." });
        }
        if (this.state.error) {
            return;
        }
        props.updateEventAmount(this.state.currentAmount, props.event, this.state.unit);
        this.setState({ currentAmount: undefined });
        props.togglePopup();
    }

    toggleConfirmPopup() {
        this.setState((prevState) => ({
            confirmPopupVisible: !prevState.confirmPopupVisible,
        }));
    }

    render() {
        const { t } = this.props;
        if (!this.props.event) {
            return null;
        }

        const amount = this.getInitialAmount(this.props);
        return (
            <>
                <Popup
                    visible={this.props.visible}
                    closeOnOutsideClick={true}
                    onHidden={this.props.togglePopup}
                    height={this.props.isEmployee ? 150 : 300}
                    title={this.props.event.title}
                    width={320}
                >
                    <div className="centeredDiv" style={{ flexDirection: "column" }}>
                        <div className="horizontalFlex" style={{ width: "100%", justifyContent: "flex-end" }}>
                            <Button type="danger" className="popupButton" onClick={this.toggleConfirmPopup}>
                                {t("Event löschen")}
                            </Button>
                        </div>
                        {(this.props.isEmployee === undefined || this.props.isEmployee === false) && (
                            <div>
                                <div className="centeredDiv verticalFlex" style={{ margin: "10px 0px" }}>
                                    <Switch
                                        defaultValue={
                                            this.props.unit ? this.props.unit === "Portionen" : this.state.switchedOn
                                        }
                                        switchedOnText={t("Portionen")}
                                        switchedOffText={t("Gewicht")}
                                        width={100}
                                        height={40}
                                        onOptionChanged={this.handleSwitchOptionChanged}
                                        style={{ float: "right" }}
                                    />
                                    <div className="centeredDiv horizontalFlex">
                                        <h2>{t("Menge")}</h2>
                                        <p className="eventAmount">
                                            <input
                                                type="number"
                                                min="0"
                                                className="eventAmountInput"
                                                defaultValue={amount}
                                                contentEditable={true}
                                                onChange={this.handleAmountChange}
                                                onKeyDown={this.handleKeyDown}
                                                style={{ width: "70px" }}
                                                onBeforeInput={this.handleAmountChange}
                                            />
                                            {t(this.state.unit)}
                                        </p>
                                    </div>
                                    {this.state.error && <div style={{ color: "red" }}>{t(this.state.error)}</div>}
                                </div>
                                <div className="centeredDiv horizontalFlex">
                                    <Button
                                        type="default"
                                        className="popupButton"
                                        onClick={(e) => this.saveUpdatedAmount(this.props, e)}
                                    >
                                        {t("Menge speichern")}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Popup>
                <Popup
                    visible={this.state.confirmPopupVisible}
                    onHiding={this.toggleConfirmPopup}
                    closeOnOutsideClick={true}
                    title={t("Event wirklich löschen?")}
                    width={350}
                    height={140}
                >
                    <div className="centeredDiv horizontalFlex">
                        <Button
                            onClick={() => {
                                this.props.deleteEvent(this.props.event);
                                this.toggleConfirmPopup();
                                this.props.togglePopup();
                            }}
                            type="danger"
                            text={t("Löschen")}
                            style={{ marginRight: 20 }}
                        />
                        <Button
                            onClick={() => {
                                this.toggleConfirmPopup();
                            }}
                            type="normal"
                            text={t("Abbrechen")}
                        />
                    </div>
                </Popup>
            </>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(CalendarEventPopup);
