import React from 'react';
import { connect } from 'react-redux';
import Calendar from '../../core/calendar/calendar';

const MyCalendars = (params) => {
    return (
        <div>
            <Calendar user={params.identity.user} type="view" />
        </div>
    );
};

function mapStateToProps(state) {
    const { identity } = state;
    return { identity };
}

export default connect(mapStateToProps)(MyCalendars);
