import moment from "moment";
import "moment/locale/de";
import React, { useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
    createCookingProcessWithArticleSizes,
    createCookingProcessWithArticleWeights,
} from "../../../actions/cookingProcessActions";
import { setCookingProcessesForArticle } from "../../../actions/menuCardAction";
import { updateEventFromTeamCalendar } from "../../../actions/teamCalendarActions";
import { history } from "../../../helpers/history";
import store from "../../../store";
import CreateCookingProcessPopup from "../../pages/dishes/createCookingProcessPopup";

moment.locale("de");
const localizer = momentLocalizer(moment);

const UseModeCalendar = ({ events, handleRangeChange, eventStyleGetter, renderEventContent, culture, eventType, tooltipAccessor }) => {
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const [selectedArticle, setSelectedArticle] = useState(undefined);

    const displayEvents = eventType
        ? eventType.name === "tasks"
            ? events.filter((event) => event.type === 1)
            : events.filter((event) => event.type === 2)
        : events;

    function handleSelectEvent(event) {
        if (event.cookingProcessStart) {
            return;
        }
        setSelectedEvent(event);
        if (event.type === 2) {
            store.dispatch(setCookingProcessesForArticle(event.articleId)).then((response) => {
                if (response.ArticleSizes.length > 0) {
                    var article = response;
                    if (event.articleWeight) {
                        article.AllCookingProcessesForThisArticle.parentCookingProcess[0].existingWeight =
                            event.articleWeight;
                    }
                    if (event.articlePortions && article.ArticleSizesWithoutDefaultSize.length > 0) {
                        for (var i = 0; i < article.ArticleSizesWithoutDefaultSize.length; i++) {
                            article.ArticleSizesWithoutDefaultSize[i].cookingProcessAmountHelper =
                                event.articlePortions;
                        }
                    }
                    setSelectedArticle(article);
                }
            });
        }
    }

    function handleSaveArticleSizes(amountForSizes, existingArticleWeights, start) {
        store
            .dispatch(
                createCookingProcessWithArticleSizes(
                    selectedArticle.articlesId,
                    amountForSizes,
                    existingArticleWeights,
                    selectedEvent.id
                )
            )
            .then((response) => {
                if (start) {
                    selectedEvent.cookingProcessStart = new Date();
                    store.dispatch(updateEventFromTeamCalendar(selectedEvent));
                    history.push("/kueche/teamkochprozesse");
                }
            });
    }

    function handleSaveArticleWeights(articleWeights, existingArticleWeights, start) {
        store
            .dispatch(
                createCookingProcessWithArticleWeights(
                    selectedArticle.articlesId,
                    articleWeights,
                    existingArticleWeights,
                    selectedEvent.id
                )
            )
            .then((response) => {
                if (start) {
                    selectedEvent.cookingProcessStart = new Date();
                    store.dispatch(updateEventFromTeamCalendar(selectedEvent));
                    history.push("/kueche/teamkochprozesse");
                }
            });
    }

    return (
        <div>
            <BigCalendar
                selectable
                localizer={localizer}
                culture={culture}
                events={displayEvents}
                startAccessor="start"
                endAccessor="end"
                onRangeChange={handleRangeChange}
                defaultView="week"
                style={{ height: 600, margin: 25 }}
                eventPropGetter={eventStyleGetter}
                components={{
                    event: renderEventContent,
                }}
                onSelectEvent={handleSelectEvent}
                tooltipAccessor={tooltipAccessor}
            />
            <CreateCookingProcessPopup
                visible={selectedArticle}
                togglePopup={() => setSelectedArticle(undefined)}
                article={selectedArticle}
                handleSaveArticleSizes={handleSaveArticleSizes}
                handleSaveArticleWeights={handleSaveArticleWeights}
                articleWeight={selectedEvent && selectedEvent.articleWeight ? selectedEvent.articleWeight : undefined}
                articlePortions={selectedEvent && selectedEvent.articlePortions ? selectedEvent.articlePortions : undefined}
            />
        </div>
    );
};

export default UseModeCalendar;
