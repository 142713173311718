import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { List } from "devextreme-react";
import $ from "jquery";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import {
    hasRight,
    viewAdditiveGroups,
    viewAdditives,
    viewArticleAvailabilities,
    viewArticleDependencyGroups,
    viewArticleGroups,
    viewArticleLinks,
    viewArticles,
    viewArticleSizes,
    viewCategories,
    viewCustomers,
    viewDiets,
    viewEmployees,
    viewManagement,
    viewOrderTypes,
    viewPhaseTypes,
    viewPriceGroups,
    viewPriceGroupsImportExport,
    viewRoles,
    viewTaxes,
    viewTools,
    viewWareTypes,
    viewWeeklyPlanComponents,
    viewWeeklyPlanSamples,
    viewWeekyPlanTemplates,
    viewWorkingstations,
} from "../../../../helpers/rights";
import "./navigation.scss";
import Star from "@material-ui/icons/Star";

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allNavigationTiles: undefined,
            home: undefined,
            expandedIndex: undefined,
        };
        this.treeViewRef = React.createRef();
        this.goToPage = this.goToPage.bind(this);
        this.treeListRenderItem = this.treeListRenderItem.bind(this);
    }

    groupByKey(array, key) {
        return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
        }, {});
    }

    componentDidMount() {
        let allNavigationTiles = [];
        let favorites = this.props.identity.user.Favorites;
        let favoriteTiles = [];

        this.props.navigation.pages.forEach((page) => {
            if (page.navigationTiles) allNavigationTiles = allNavigationTiles.concat(page.navigationTiles);
        });

        if (favorites) {
            favoriteTiles = allNavigationTiles.filter((tile) =>
                favorites.some((element) => element.route === tile.route)
            );
        }

        if (favoriteTiles) {
            favoriteTiles = favoriteTiles.map((tile) => {
                tile.isFavorite = true;
                return tile;
            });
        }

        var home = Object.entries(this.groupByKey(allNavigationTiles, "navGroup"))[0][1];
        home.push({
            title: "Favoriten",
            navGroup: "Home",
            hasRight: true,
            icon: Star,
            navigationTiles: favoriteTiles,
            isNavigationPage: true,
        });

        this.setState({
            allNavigationTiles: allNavigationTiles,
            home: home,
        });
    }

    checkHasMinimumRightsManagement() {
        return (
            hasRight(viewTools) ||
            hasRight(viewWorkingstations) ||
            hasRight(viewArticleSizes) ||
            hasRight(viewOrderTypes) ||
            hasRight(viewArticleDependencyGroups) ||
            hasRight(viewDiets) ||
            hasRight(viewCategories) ||
            hasRight(viewPhaseTypes) ||
            hasRight(viewPriceGroups) ||
            hasRight(viewTaxes) ||
            hasRight(viewArticleAvailabilities) ||
            hasRight(viewWareTypes) ||
            hasRight(viewAdditiveGroups) ||
            hasRight(viewAdditives) ||
            hasRight(viewEmployees) ||
            hasRight(viewRoles) ||
            hasRight(viewPriceGroupsImportExport) ||
            hasRight(viewCustomers) ||
            hasRight(viewArticleGroups) ||
            hasRight(viewArticles) ||
            hasRight(viewArticleLinks) ||
            hasRight(viewWeekyPlanTemplates) ||
            hasRight(viewWeeklyPlanComponents) ||
            hasRight(viewWeeklyPlanSamples) ||
            hasRight(viewManagement) ||
            hasRight(viewManagement)
        );
    }

    treeListRenderItem(item, listItem, recursionDepth) {
        const page = this.props.navigation.pages.filter((p) => p.route === item.route)[0];
        const index = this.state.allNavigationTiles.indexOf(item);
        const { t } = this.props;
        if (!page || !page.isNavigationPage)
            return (
                <span
                    onClick={() => this.goToPage(item.route)}
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: "14px",
                        marginLeft: 10,
                        marginTop: 10,
                    }}
                >
                    <item.icon
                        icon={item.iconText ? item.iconText : ""}
                        style={{ marginRight: 5, width: "24px", height: "24x", fontSize: "24px" }}
                    />
                    {t(item.title)}
                </span>
            );
        else
            return (
                <div style={{ marginLeft: 10, marginTop: 10 }}>
                    <span
                        onClick={() => {
                            $(".subNavItem" + index + "_" + recursionDepth).toggleClass("open");
                            // $(".navItem" + listItem.itemIndex).toggleClass("open");
                        }}
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            fontSize: "14px",
                        }}
                    >
                        <item.icon
                            icon={item.iconText ? item.iconText : ""}
                            style={{ marginRight: 5, fontSize: "24px" }}
                        />
                        {t(item.title)}
                        <span style={{ position: "absolute", right: 5 }}>
                            <KeyboardArrowDown style={{ fontSize: "24px" }} />
                        </span>
                    </span>

                    <div className={"subNavContainer subNavItem" + index + "_" + recursionDepth}>
                        <List
                            keyExpr="route"
                            items={page.navigationTiles ? page.navigationTiles.filter((tile) => tile.hasRight) : []}
                            onItemClick={(e) => {
                                const page = this.props.navigation.pages.filter((p) => p.route === e.itemData.route)[0];
                                if (!page || !page.isNavigationPage) {
                                    this.props.toggleNavigation();
                                    this.goToPage(e.itemData.route);
                                }
                            }}
                            style={{
                                marginLeft: 20,
                            }}
                            itemRender={(item) => this.treeListRenderItem(item, listItem, recursionDepth + 1)}
                        />
                    </div>
                </div>
            );
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.props.identity.user.Token && (
                    <div
                        id="navigation"
                        style={{ width: "350px", boxShadow: "0px 0px 5px #000", backgroundColor: "#eee" }}
                    >
                        {this.state.allNavigationTiles && (
                            <List
                                items={this.state.home.filter(
                                    (h) =>
                                        h.hasRight &&
                                        (!h.hideTile ||
                                            h.title === "Navigation" ||
                                            h.title === "Zurück" ||
                                            h.title === "Startseite")
                                )}
                                onItemClick={(e) => {
                                    if (e.itemData.title === "Navigation") {
                                        this.props.toggleNavigation();
                                    } else if (e.itemData.title === "Zurück") {
                                        if (window.location.hash.includes("/kochprozess/")) {
                                            this.props.history.go(-2);
                                        } else {
                                            this.props.history.goBack();
                                        }
                                    } else if (e.itemData.title === "Startseite") {
                                        this.goToPage("/");
                                    } else if (e.itemData.isNavigationPage) {
                                        if (!this.props.open) {
                                            this.goToPage(e.itemData.route);
                                        }
                                    }
                                }}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                itemRender={(e) => {
                                    const index = this.state.home.filter((h) => h.hasRight).indexOf(e);
                                    return (
                                        <div>
                                            {e.isNavigationPage && (
                                                <React.Fragment>
                                                    <span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            fontSize: "14px",
                                                        }}
                                                        title={t(e.title)}
                                                        onClick={() => {
                                                            $(".navItem" + index).toggleClass("open");
                                                        }}
                                                    >
                                                        <e.icon
                                                            fontSize={"large"}
                                                            icon={e.iconText ? e.iconText : ""}
                                                            size="lg"
                                                        />
                                                        <span style={{ marginLeft: 15 }}>{t(e.title)}</span>{" "}
                                                        <span style={{ position: "absolute", right: 5 }}>
                                                            <KeyboardArrowDown style={{ fontSize: "24px" }} />
                                                        </span>
                                                    </span>

                                                    <div className={"navContainer navItem" + index}>
                                                        {this.props.open && (
                                                            <List
                                                                dataStructure="plain"
                                                                virtualModeEnabled
                                                                rootValue={0}
                                                                keyExpr="route"
                                                                items={this.state.allNavigationTiles.filter(
                                                                    (tile) => e.title === tile.navGroup && tile.hasRight
                                                                )}
                                                                onItemClick={(e) => {
                                                                    const page = this.props.navigation.pages.filter(
                                                                        (p) => p.route === e.itemData.route
                                                                    )[0];
                                                                    if (!page || !page.isNavigationPage) {
                                                                        this.props.toggleNavigation();
                                                                        this.goToPage(e.itemData.route);
                                                                    }
                                                                }}
                                                                expandAllEnabled={false}
                                                                itemRender={(item) =>
                                                                    this.treeListRenderItem(item, e, 0)
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {!e.isNavigationPage && (
                                                <span
                                                    onClick={() => this.goToPage(e.route)}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                    title={t(e.title)}
                                                >
                                                    <e.icon
                                                        fontSize={this.props.navigationOpen ? "" : "large"}
                                                        icon={e.iconText ? e.iconText : ""}
                                                        style={{ marginRight: 10 }}
                                                    />
                                                    {t(e.title)}
                                                </span>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }

    goToPage(path) {
        this.props.history.push(path);
    }
}

function mapStateToProps(state) {
    const { identity, navigation } = state;
    return {
        identity,
        navigation,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]), withRouter)(Navigation);
