import moment from "moment";
import "moment/locale/de";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getGrammageUnit } from "../../../helpers/units";
import CalendarEventPopup from "./calendarEventPopup";
import CalendarSlotPopup from "./calendarSlotPopup";

moment.locale("de");
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const FillModeCalendar = ({
    events,
    handleEventDrop,
    handleEventResize,
    handleRangeChange,
    eventStyleGetter,
    culture,
    handleAmountChange,
    handleEventDelete,
    onDropFromOutside,
    dragFromOutsideItem,
    recipes,
    employees,
    teamCalendarId,
    forceRefetch,
    eventType,
    hasActivePopup,
    tooltipAccessor,
}) => {
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const [isEventPopupVisible, setIsEventPopupVisible] = useState(undefined);
    const [selectedSlot, setSelectedSlot] = useState(undefined);
    const [isSlotPopupVisible, setIsSlotPopupVisible] = useState(undefined);
    const [eventIsEmployee, setEventIsEmployee] = useState(false);
    const [calendarStyle, setCalendarStyle] = useState({});

    useEffect(() => {
        if (isEventPopupVisible || isSlotPopupVisible || hasActivePopup) {
            setCalendarStyle({ pointerEvents: "none" });
        } else {
            setCalendarStyle({});
        }
    }, [isEventPopupVisible, isSlotPopupVisible, hasActivePopup]);

    function handleEventDoubleClick(event) {
        if (!event.cookingProcessStart) {
            setEventIsEmployee(event.employeeId);
            setSelectedEvent(event);
            setIsEventPopupVisible(true);
        }
    }

    function toggleEventPopup() {
        setIsEventPopupVisible(!isEventPopupVisible);
        setSelectedEvent(undefined);
    }

    function handleSelectSlot(slot) {
        setIsSlotPopupVisible(true);
        setSelectedSlot(slot);
    }

    function toggleSlotPopup() {
        setIsSlotPopupVisible(!isSlotPopupVisible);
        setSelectedSlot(undefined);
    }

    const renderEventContent = useCallback((slotInfo) => {
        if (slotInfo.event.articleId) {
            let amount = "";
            let unit = "";
            if (slotInfo.event.articleWeight > 0) {
                if (getGrammageUnit(slotInfo.event.articleWeight) === "kg") {
                    amount = slotInfo.event.articleWeight / 1000;
                    unit = "kg";
                } else {
                    amount = slotInfo.event.articleWeight;
                    unit = "g";
                }
            } else {
                amount = slotInfo.event.articlePortions;
                unit = "x";
            }
            return (
                <div>
                    <p>{amount + unit + " " + slotInfo.title}</p>
                    {slotInfo.event.type === 2 && (
                        <p>
                            ({slotInfo.event.currentEmployees}/{Math.max(1, slotInfo.event.requiredEmployees.length)})
                        </p>
                    )}
                </div>
            );
        } else if (slotInfo.event.employeeId) {
            return (
                <div>
                    <p>{slotInfo.title}</p>
                </div>
            );
        }
        return null;
    }, []);

    return (
        <>
            <DragAndDropCalendar
                localizer={localizer}
                culture={culture}
                events={events}
                startAccessor="start"
                endAccessor="end"
                resizable
                selectable
                onEventDrop={handleEventDrop}
                onEventResize={handleEventResize}
                onRangeChange={handleRangeChange}
                onDoubleClickEvent={handleEventDoubleClick}
                defaultView="week"
                style={{...calendarStyle, ...{ height: 600, margin: 25 }}}
                eventPropGetter={eventStyleGetter}
                components={{
                    event: renderEventContent,
                }}
                draggableAccessor={(event) => true}
                onDropFromOutside={onDropFromOutside}
                dragFromOutsideItem={dragFromOutsideItem}
                onSelectSlot={handleSelectSlot}
                tooltipAccessor={tooltipAccessor}
            />
            <CalendarEventPopup
                event={selectedEvent}
                visible={isEventPopupVisible}
                togglePopup={toggleEventPopup}
                updateEventAmount={handleAmountChange}
                deleteEvent={handleEventDelete}
                isEmployee={eventIsEmployee}
                unit={
                    selectedEvent && selectedEvent.articlePortions && selectedEvent.articlePortions > 0
                        ? "Portionen"
                        : "g"
                }
            />
            <CalendarSlotPopup
                events={events}
                recipes={recipes}
                employees={employees}
                slot={selectedSlot}
                visible={isSlotPopupVisible}
                togglePopup={toggleSlotPopup}
                teamCalendarId={teamCalendarId}
                forceRefetch={forceRefetch}
                eventType={eventType}
            />
        </>
    );
};

export default FillModeCalendar;
