import moment from "moment";
import "moment/locale/de";
import React from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("de");
const localizer = momentLocalizer(moment);

const ViewModeCalendar = ({ events, handleRangeChange, eventStyleGetter, renderEventContent, culture, tooltipAccessor }) => {
    return (
        <BigCalendar
            selectable
            localizer={localizer}
            culture={culture}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onRangeChange={handleRangeChange}
            defaultView="week"
            style={{ height: 600, margin: 25 }}
            eventPropGetter={eventStyleGetter}
            components={{
                event: renderEventContent,
            }}
            tooltipAccessor={tooltipAccessor}
        />
    );
};

export default ViewModeCalendar;
